<template>
  <div class="peopleReactionDetail">
    <div class="peopleReactionDetail-t">
      <img :src="postData.picture" alt="" />
    </div>
    <div class="peopleReactionDetail-c">
      <div class="item">
        <div class="item-l">反馈时间：</div>
        <div class="item-r">{{ postData.insertTime }}</div>
      </div>
      <div class="item">
        <div class="item-l">反映群众：</div>
        <div class="item-r">{{ postData.reactionUser }}</div>
      </div>
      <div class="item">
        <div class="item-l">手机号码：</div>
        <div class="item-r">{{ postData.reactionPhone }}</div>
      </div>
      <div class="item">
        <div class="item-l">事件地点：</div>
        <div class="item-r">{{ postData.detailedAddress }}</div>
      </div>
      <div class="item">
        <div class="item-l">事件描述：</div>
        <div class="item-r">{{ postData.description }}</div>
      </div>
    </div>
    <div class="peopleReactionDetail-b">
      <van-steps direction="vertical" active-color="#FB8133" :active="active">
        <van-step>
          <h3>提交</h3>
        </van-step>
        <van-step>
          <h3>受理</h3>
        </van-step>
        <van-step>
          <h3>办结</h3>
          <div class="remark" v-if="postData.disposeDescription">
            {{ postData.disposeDescription }}
          </div>
          <div
            class="img"
            v-if="postData.disposePic && postData.disposePic.length != 0"
          >
            <img
              :src="item"
              v-for="(item, index) in postData.disposePic"
              :key="index"
              @click="preview(item)"
              alt=""
            />
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview } from "vant";
import { Step, Steps } from "vant";
Vue.use(ImagePreview);
Vue.use(Step);
Vue.use(Steps);
import wx from "weixin-js-sdk";
import { getDetailsMiniURL } from "./api";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "peopleReactionDetail",
  data() {
    return {
      id: "",
      postData: {
        disposePic: [],
      },
      active: 0,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    this.getInfo();
  },
  mounted() {},
  methods: {
    preview(item) {
      ImagePreview([item]);
    },
    async getInfo(id) {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(`${getDetailsMiniURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
        if (this.postData.status == 0) {
          this.active = 0;
        } else if (this.postData.status == 1) {
          this.active = 1;
        } else if (this.postData.status == 3) {
          this.active = 2;
        }
        if (this.postData.disposePic) {
          this.postData.disposePic = this.postData.disposePic.split(",");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.peopleReactionDetail {
  min-height: 100vh;
  .peopleReactionDetail-t {
    img {
      width: 100%;
    }
  }
  .peopleReactionDetail-c {
    padding: 30px 50px 44px;
    background: #fff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    .item {
      display: flex;
    }
    .item-l {
      width: 130px;
      font-weight: 400;
      font-size: 26px;
      color: #333333;
      line-height: 42px;
      flex-shrink: 0;
    }
    .item-r {
      font-weight: 400;
      font-size: 26px;
      color: #6d7278;
      line-height: 42px;
    }
  }
  .peopleReactionDetail-b {
    padding: 54px 70px;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    h3 {
      font-weight: 400;
      font-size: 28px;
      color: #666666;
      line-height: 40px;
    }
    .remark {
      margin: 6px 0;
      font-weight: 400;
      font-size: 24px;
      color: #b4b4b4;
      line-height: 34px;
    }
    .img {
      img {
        width: 106px;
        height: 106px;
        margin: 0 20px 20px 0;
      }
    }
    /deep/ .van-step__circle {
      width: 28px;
      height: 28px;
    }
    /deep/ .van-step--vertical .van-step__circle-container {
      font-size: 32px;
    }
    /deep/ .van-step--vertical:not(:last-child)::after {
      border: 0;
    }
  }
}
</style>
